import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import './Header.scss';

class Header extends Component {

    render() {
        return (
            <section className="header container">
                <Link to="/">
              {/* <img className="header__logo" src={logo} alt="logo logo2" /> */}
              <h1 style={{ "textAlign": "center"}}>NoahGram</h1>
                </Link>
            </section>
            
        )
    }
}

export default Header;