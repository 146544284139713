const API_URL = process.env.REACT_APP_API_URL;
const HOME_URL = process.env.REACT_APP_HOME_URL;
const SITE_KEY = process.env.REACT_APP_SITE_KEY;

const env_vars = {
  API_URL,
  HOME_URL,
  SITE_KEY
};

export default env_vars;
